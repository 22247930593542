import HomeHeader from "../components/HomeHeader";

const Home = () => {

  return (
    <div className="container-horizontal">
      <HomeHeader />
    </div>
  );
};

export default Home;
