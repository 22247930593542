import { useEffect, useState } from "react";
import { exemple_socials } from "../data/socials";
import { useTranslation } from "react-i18next";


interface HeaderBoxProps {
  isHovered: boolean;
}

const HomeHeaderBox = ({ isHovered }: HeaderBoxProps) => {
  const [isVisible, setIsVisible] = useState(!isHovered);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isHovered) {
      setIsVisible(true);
    }
  }, [isHovered]);

  const handleAnimationEnd = () => {
    if (isHovered) {
      setIsVisible(false);
    }
  };

  return (
    isVisible && (
      <section
        className={`home__header-box ${isHovered ? "slide-down" : "slide-up"}`}
        onAnimationEnd={handleAnimationEnd}
      >
        <h2 className="home__header-content">
        {t('page:home')}<span>.</span>
        </h2>
        <div className="home__header-title">
          <p className="home__header-title-one">{t('home:press')}</p>
          <p className="home__header-title-two">
            Ctrl<span>+</span>{t('home:space')}
          </p>
        </div>
        <div>
          <p className="home__header-subtitle">{t('home:subject')}</p>
          <p className="home__header-mini">
            <span>[ </span>{t('page:home')}<span>]</span>
          </p>
        </div>
        <ul className="home__header-techs">
          {exemple_socials.map((social, index) => (
            <li key={index} className="home__header-tech-item">
              <button className="home__header-tech-button">
                <img
                  className="home__header-tech-icon"
                  src={`imgs/` + social.image + `.png`}
                  alt={social.name}
                />
              </button>
            </li>
          ))}
        </ul>
        <div className="home__header-slug">
          <p>
            <span>[</span> {t('home:desc')}
            <span>]</span>
          </p>
        </div>
      </section>
    )
  );
};

export default HomeHeaderBox;
