import { useState } from "react";

export const useFormValidation = () => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateName = (name: string): boolean => {
    const re = /^[a-zA-ZàèìòùÀÈÌÒÙ\s]{2,50}$/;
    return re.test(name);
  };

  const validateMessage = (message: string): boolean => {
    return message.trim().length >= 10 && message.trim().length <= 1000;
  };

  const validateForm = (name: string, email: string, message: string): boolean => {
    const newErrors: Record<string, string> = {};

    if (!validateName(name)) {
      newErrors.name = "Nome non valido. Assicurati di non usare caratteri speciali e di avere una lunghezza tra 2 e 50 caratteri.";
    }

    if (!validateEmail(email)) {
      newErrors.email = "Email non valida.";
    }

    if (!validateMessage(message)) {
      newErrors.message = "Il messaggio deve contenere tra 10 e 1000 caratteri.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearError = (field: string) => {
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[field];
      return updatedErrors;
    });
  };

  return {
    errors,
    validateForm,
    clearError,
  };
};
