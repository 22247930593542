import { useState } from 'react';

export const useAuth = () => {
  const [token, setToken] = useState(() => {
    // Controlla se il token esiste già in localStorage
    const savedToken = localStorage.getItem('token');
    return savedToken ? savedToken : null; // Se esiste lo restituisce, altrimenti null
  });

  // Funzione per eseguire il login
  const login = async () => {
    try {
      const response = await fetch('https://dmz-server.onrender.com/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: 'user', password: 'password123' }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || 'Login failed');
      }

      // Qui settiamo il token nello stato e nel localStorage
      setToken(result.token);  // Imposta lo stato con il token
      localStorage.setItem('token', result.token);  // Salva anche nel localStorage

      return result;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  // Funzione per eseguire il logout
  const logout = () => {
    setToken(null); // Rimuove il token dallo stato
    localStorage.removeItem('token'); // Rimuove il token da localStorage
  };

  // Funzione per ottenere il token
  const getToken = () => {
    return token; // Restituisce il token salvato nello stato
  };

  return {
    token,
    login,
    logout,
    getToken,
  };
};
