import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";


const useRandomMessage = () => {
  const { t } = useTranslation();
  
  const messages = [
    t('home:prompt1'),
    t('home:prompt2'),
    t('home:prompt3'),
  ];
  
  const [message, setMessage] = useState(messages[0]);


  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setMessage(messages[randomIndex]);
    }, 6000); 

    return () => clearInterval(interval); 
  }, []);

  return message; 
};

export default useRandomMessage;
