import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const COMMANDS = [
  'present()',
  'skills()',
  'projects()',
  'contacts()',
  'sendMessage()',
  'exit()',
];

const useTerminalCommands = () => {
    const [output, setOutput] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate(); 
  const { t } = useTranslation();


  const handleCommand = (command: string) => {
    
    if (!/.+\(\)$/.test(command)) {
      showError(); 
      return;
    }

    switch (command) {
      case 'present()':
        addOutput('Hi, I am a developer passionate about technology!');
        break;
        case "skills()":
            addOutput("Navigating to skills page...");
            navigate("/skills"); 
            break;
          case "projects()":
            addOutput("Navigating to projects page...");
            navigate("/projects"); 
            break;
          case "contacts()":
            addOutput("Navigating to contact page...");
            navigate("/contact"); 
            break;
          case "sendMessage()":
            addOutput("Please type your message below.");
            break;
          case "exit()":
            addOutput("Closing terminal... Goodbye!");
            break;
          default:
            showError(); 
            break;
    }
  };

  const addOutput = (line: string) => {
    setOutput((prev) => [...prev, line]);
    setErrorMessage(null);
  };

  const showError = () => {
    setErrorMessage(`${t('home:cmdError')}\n${COMMANDS.join('\n')}`);
  };

  return {
    output,
    errorMessage,
    handleCommand,
  };
};

export default useTerminalCommands;
