import { socialType } from "../models/socialType";

export const exemple_socials: socialType[] = [
    {
      name: "Github",
      link: "https://github.com/dimainc26",
      image: "github",
      colors: ["black", "white"],
    },
    {
      name: "Tiktok",
      link: "https://github.com/dimainc26",
      image: "tiktok",
      colors: ["black", "white"],
    },
    {
      name: "Youtube",
      link: "https://github.com/dimainc26",
      image: "youtube",
      colors: ["red", "white"],
    },
    {
      name: "Facebook",
      link: "https://github.com/dimainc26",
      image: "facebook",
      colors: ["black", "white"],
    },
    {
      name: "Linkedin",
      link: "https://github.com/dimainc26",
      image: "linkedin",
      colors: ["blue", "white"],
    },
  ];
  