// services/authService.ts
import axios from "axios";

export const fetchJWTToken = async () => {
  try {
    const response = await axios.post('https://dmz-server.onrender.com/api/auth/login', {
        "username": "user",
        "password": "password123"
      }, {
        maxRedirects: 0
      } );
    const token = response.data.token;

    localStorage.setItem("jwtToken", token);
    return null;

  } catch (error) {
    console.error("Error fetching JWT token:", error);
    throw new Error("Failed to fetch token");
  }
};
