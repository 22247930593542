import { useEffect, useState } from "react";
import HomeHeaderBox from "./HomeHeaderBox";
import CommandLine from "./CommandLine";

const HomeHeader = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isCtrlOrCmd = event.ctrlKey || event.metaKey;
      const isSpace = event.code === "Space";

      if (isCtrlOrCmd && isSpace) {
        setIsHovered(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleCloseCommandLine = () => {
    setIsHovered(false);
  };

  return (
    <div className="home__header-container">
      {!isHovered && <HomeHeaderBox isHovered={false} />}

      {isHovered && <CommandLine isHovered={true} onClose={handleCloseCommandLine} />}

      <div className="home__header-media">
        <img
          src="./me/me2.png"
          className="home__header-media-img"
        />
      </div>
    </div>
  );
};

export default HomeHeader;
