// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationITA from './locales/ita/translation.json';
import translationFR from './locales/fr/translation.json';

const resources = {
  en: {
    common: translationEN.common,
    form: translationEN.form,
    home: translationEN.home,
    skills: translationEN.skills,
    projects: translationEN.projects,
    contact: translationEN.contact,
    page: translationEN.page,
  },
  it: {
    common: translationITA.common,
    form: translationITA.form,
    home: translationITA.home,
    skills: translationITA.skills,
    projects: translationITA.projects,
    contact: translationITA.contact,
    page: translationITA.page,
  },
  fr: {
    common: translationFR.common,
    form: translationFR.form,
    home: translationFR.home,
    skills: translationFR.skills,
    projects: translationFR.projects,
    contact: translationFR.contact,
    page: translationFR.page,
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    ns: ['common', 'form'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false // React already safes from xss
    }
  });

export default i18n;
