import React from "react";
import router from "./router/routes";
import { RouterProvider } from "react-router-dom";

import './App.scss'

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
