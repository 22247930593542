import { createBrowserRouter } from "react-router-dom";
import Root from "@components/Root";
import NotFound from "@views/PageNotFound.tsx";
import { fetchJWTToken } from "@services/fetchJWToken.ts";
import { ProjectsProvider } from "@context/ProjectsContext.tsx";
import { SkillsProvider } from "@context/SkillsContext.tsx";
import Home from "@views/Home";
import Contact from "@views/Contact";

import { Suspense, lazy } from "react";

const Skills = lazy(() => import("../views/Skills"));
const Projects = lazy(() => import("../views/Projects"));

const router = createBrowserRouter([
  {
    path: "/",
    element:( 
      <ProjectsProvider>
        <SkillsProvider>
          <Root />
        </SkillsProvider>
      </ProjectsProvider>
  ),
    errorElement: <NotFound />,
    loader: fetchJWTToken,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/skills",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Skills />
          </Suspense>
        ),
      },
      {
        path: "/projects",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Projects />
          </Suspense>
        ),
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);

export default router;
