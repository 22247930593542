import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { fetchProjects } from "@services/fetchProjects.ts";
import { Project } from "../models/projectType";
import { db } from "@services/dexieDB";

interface ProjectsContextType {
  projects: Project[] | null;
  loading: boolean;
  error: string | null;
}

const ProjectsContext = createContext<ProjectsContextType | undefined>(undefined);

export const useProjects = (): ProjectsContextType => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error("useProjects must be used within a ProjectsProvider");
  }
  return context;
};

export const ProjectsProvider = ({ children }: { children: ReactNode }) => {
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadProjects = async () => {
      setLoading(true);
      try {
        const cachedProjects = await db.projects.toArray();
        if (cachedProjects.length > 0) {
          setProjects(cachedProjects);
        }

        const data = await fetchProjects();

        setProjects(data);
        await db.projects.clear();  
        await db.projects.bulkAdd(data);  
      } catch (err) {
        setError("Failed to load projects.");
      } finally {
        setLoading(false);
      }
    };

    loadProjects();
  }, []);

  return (
    <ProjectsContext.Provider value={{ projects, loading, error }}>
      {children}
    </ProjectsContext.Provider>
  );
};
