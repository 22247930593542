import React, { useEffect, useState, useRef } from 'react';

const generateSpritePaths = (count: number): string[] => {
  // eslint-disable-next-line prefer-const
  let paths = [];
  for (let i = 0; i <= count; i++) {
    const formattedIndex = i < 10 ? `00${i}` : `0${i}`;
    paths.push(`/mecha/dance/dance_${formattedIndex}.png`);
  }
  return paths;
};

const FormStatus: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const [cyclesCompleted, setCyclesCompleted] = useState<number>(0);
  const totalCycles = 4; 
  const spriteCount = 60;

  const mechaPaths = generateSpritePaths(spriteCount);
  const intervalId = useRef<number | null>(null);

  const startAnimation = () => {
    setCyclesCompleted(0);
    setIndex(0);
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      setIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % mechaPaths.length;
        if (newIndex === 0) {
          setCyclesCompleted((prevCycles) => prevCycles + 1);
        }
        return newIndex;
      });
    }, 30);
  };

  useEffect(() => {
    startAnimation();

    return () => {
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (cyclesCompleted >= totalCycles && intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [cyclesCompleted, totalCycles]);

  return (
    <div className="form__status">
      <div className="mecha__box" onClick={startAnimation}>
        <img
          className="mecha__animation"
          src={mechaPaths[index]}
          alt="Mecha Animation"
        />
      </div>
      {/* <div className="form__infos">
        <div className='form__infos-item'>
          <img src="/imgs/email.png" alt="logo" />
          <div>
            <p>Email</p>
            <p>dimazanre@gmail.com</p>
          </div>
        </div>
        <div className='form__infos-item'>
          <img src="/imgs/phone.png" alt="logo" />
          <div>
            <p>Telefono</p>
            <p>dimazanre@gmail.com</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default FormStatus;
