import useTerminalCommands from "@hooks/useTerminalCommands";
import useRandomMessage from "@hooks/useRandomMessage";
import CommandInput from "@components/CommandInput";
import { useEffect, useRef, useState } from "react";

interface CommandLineProps {
  isHovered: boolean;
  onClose: () => void;
}

const CommandLine = ({ isHovered, onClose }: CommandLineProps) => {
  const [, setIsVisible] = useState(isHovered);
  const { output, errorMessage, handleCommand } = useTerminalCommands();
  const randomMessage = useRandomMessage();
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isHovered) {
      setIsVisible(true);
    }
  }, [isHovered]);

  const handleAnimationEnd = () => {
    if (!isHovered) {
      setIsVisible(false);
    }
  };

  return (
    <section
      onAnimationEnd={handleAnimationEnd}
      className={`command_box home__header-box ${
        isHovered ? "slide-down2" : "slide-up2"
      }`}
    >
      <div className="editor-container">
        <div className="editor-header">
          <p className="editor-title">dimaX</p>
          <div className="editor-icons">
            <span className="editor-icon red" onClick={onClose}></span>
            <span className="editor-icon yellow"></span>
            <span className="editor-icon green"></span>
          </div>
        </div>
        <div className="editor-box" id="editorBox" onClick={focusInput}>
          {output.map((line, index) => (
            <p key={index} className="editor-line">
              {line}
            </p>
          ))}
          <p className="editor-comments">{randomMessage}</p>
          {errorMessage && (
            <pre className="editor-error-line">{errorMessage}</pre>
          )}
          <CommandInput onCommand={handleCommand} inputRef={inputRef} />
        </div>
      </div>
      <p className="home__header-mini">
        <span>[ </span>Terminal <span>]</span>
      </p>
    </section>
  );
};

export default CommandLine;
