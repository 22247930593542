/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useFormValidation } from "@hooks/useFormValidation";
import { useSendEmail } from "@hooks/useSendEmail";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Form = () => {
  const {t} = useTranslation()

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate()

  const { errors, validateForm, clearError } = useFormValidation();
  const { sendEmail } = useSendEmail();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm(name, email, message)) {
      setIsSubmitting(true);

      try {
        const result = await sendEmail({ name, email, message });
        toast.success(result.msg || "Email inviata con successo!");

        navigate('/')

        setName("");
        setEmail("");
        setMessage("");
      } catch (error: any) {
        toast.error(error.message || "Errore durante l'invio della mail.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="form">
      <form className="form__body" onSubmit={handleSubmit}>
        <div className="form__group">
          <label htmlFor="name">
            {t('form:name')}:
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
          </label>
          <input
            type="text"
            className={`form__body-input ${errors.name ? "input-error" : ""}`}
            id="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              clearError("name");
            }}
            disabled={isSubmitting}
          />
        </div>

        <div className="form__group">
          <label htmlFor="email">
          {t('form:email')}:
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </label>
          <input
            type="email"
            className={`form__body-input ${errors.email ? "input-error" : ""}`}
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              clearError("email");
            }}
            disabled={isSubmitting}
          />
        </div>

        <div className="form__group">
          <label htmlFor="message">
          {t('form:message')}:
            {errors.message && (
              <span className="error-message">{errors.message}</span>
            )}
          </label>
          <textarea
            className={`form__body-textarea ${
              errors.message ? "input-error" : ""
            }`}
            id="message"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              clearError("message");
            }}
            disabled={isSubmitting}
          />
        </div>

        <button
          type="submit"
          className="form__body-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Invio..." : "Invia"}
        </button>
      </form>
    </div>
  );
};

export default Form;
