import { useState, useEffect } from "react";
import FormStatus from "@components/FormStatus";
import Form from "@components/Form";
import FormHeader from "@components/FormHeader";

const Contact = () => {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container-horizontal">
      <div className="flex">
        <FormHeader />
        <FormStatus />
      </div>

      {showForm && (
        <div className="form-overlay">
          <Form />
        </div>
      )}
    </div>
  );
};

export default Contact;
