import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const [index, setIndex] = useState(0);
  const [mecha, setMecha] = useState<string[]>([]);
  const totalCycles = 3;
  const animationFrameRate = 30;
  const totalSprites = 60; 

  useEffect(() => {
    const generateSpritePaths = (count: number) => {
      const paths = [];
      for (let i = 0; i <= count; i++) {
        const formattedNumber = i < 10 ? `00${i}` : `0${i}`;
        paths.push(`/mecha/first_anim/first_anim_${formattedNumber}.png`);
      }
      return paths;
    };

    const preloadSprite = (imagePath: string) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = imagePath;
        img.onload = () => resolve();
      });
    };

    const preloadSprites = async () => {
      const spritePaths = generateSpritePaths(totalSprites);
      await Promise.all(spritePaths.map(preloadSprite));
      setMecha(spritePaths);
    };

    preloadSprites();
  }, []);

  useEffect(() => {
    if (mecha.length === 0) return;

    let cycles = 0;
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex + 1 >= mecha.length) {
          cycles++;
          if (cycles >= totalCycles) {
            clearInterval(interval);
          }
          return 0; // Resetta l'indice
        }
        return prevIndex + 1;
      });
    }, animationFrameRate);

    return () => clearInterval(interval);
  }, [mecha]);

  return (
    <div className="not-container">
      <div className="mecha-box">
        <img className="mecha-img" src={mecha[index]} alt="Mecha Animation" />
      </div>
      <div className="not-box">
        <h1 className="title">
          Ooops<span>.</span>
        </h1>
      
        <p className="third-title">
          Go back.
        </p>
        <Link to="/" className="nav-btn">
          Home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
