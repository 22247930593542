import { useState } from "react";

interface CommandInputProps {
    onCommand: (command: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
  }
  
  const CommandInput = ({ onCommand, inputRef }: CommandInputProps) => {
    const [input, setInput] = useState("");
  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      onCommand(input);
      setInput("");
    };
  
    return (
      <form onSubmit={handleSubmit} className="editor-input-container">
        <span className="editor-input-prompt"> </span>
        <input
          type="text"
          className="editor-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          ref={inputRef}
          autoFocus
        />
      </form>
    );
  };

  export default CommandInput;