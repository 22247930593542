import { Skill } from "@models/skillType";
import axios from "axios";

export const fetchSkills = async () => {
    try {

        const token = localStorage.getItem("jwtToken");

        if (!token) {
            throw new Error("Token not found");
        }

        const response  = await axios.get('https://dmz-server.onrender.com/api/repos/dmz',
        {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const skills:Skill[] = response.data;
        return skills;
    } catch (error) {
        console.error('Error fetching projects:', error);
        throw new Error('Failed to fetch projects');
    }
};