import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { fetchSkills } from "@services/fetchSkills";
import { Skill } from "@models/skillType";
import { db } from "@services/dexieDB";

interface SkillsContextType {
  skills: Skill[] | null;
  loading: boolean;
  error: string | null;
}

const SkillsContext = createContext<SkillsContextType | undefined>(undefined);

export const useSkills = (): SkillsContextType => {
  const context = useContext(SkillsContext);
  if (!context) {
    throw new Error("useSkills must be used within a SkillsProvider");
  }
  return context;
};

export const SkillsProvider = ({ children }: { children: ReactNode }) => {
  const [skills, setSkills] = useState<Skill[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadSkills = async () => {
      setLoading(true);
      try {
        const cachedSkills = await db.skills.toArray();
        if (cachedSkills.length > 0) {
          setSkills(cachedSkills);
        }

        const data = await fetchSkills();

        setSkills(data);
        await db.skills.clear();  
        await db.skills.bulkAdd(data);  
      } catch (err) {
        setError("Failed to load skills.");
      } finally {
        setLoading(false);
      }
    };

    loadSkills();
  }, []);

  return (
    <SkillsContext.Provider value={{ skills, loading, error }}>
      {children}
    </SkillsContext.Provider>
  );
};
