import { useTranslation } from "react-i18next";


const FormHeader = () => {
  const {t} = useTranslation()


  return (
    <div className="form__header-container">
      <section className="form__header-box">
        <h2 className="form__header-content">
          Contatti<span>.</span>
        </h2>
        <div className="form__header-title">
          <p className="form__header-title-one">{t('contact:stay')}</p>
          <p className="form__header-title-two">
          {t('contact:connect')}<span>.</span>
          </p>
        </div>
        <div>
          <p className="form__header-subtitle">{t('contact:innovation')}</p>
          <a href="mailto:dimazanre@gmail.com" className="form__header-mini">
            <span>[ </span>dimazanre@gmail.com <span>]</span>
          </a>
          <a href="tel:+39 375 557 8879" className="form__header-mini">
            <span>[ </span>+39 375 557 8879 <span>]</span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default FormHeader;
