import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.scss'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n.ts'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
      <ToastContainer />
    </I18nextProvider>
  </React.StrictMode>,
)
