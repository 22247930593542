import { useAuth } from "./useAuth";

export const useSendEmail = () => {
  const { login, getToken } = useAuth();

  const sendEmail = async (data: { name: string; email: string; message: string }) => {
    try {

      await login();

  const token = getToken();


      if (!token) {
        throw new Error("Riprova");
      }

      const response = await fetch("https://dmz-server.onrender.com/api/msg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || 'Erreur inconnue.');
      }

      return result;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  return { sendEmail };
};