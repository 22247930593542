import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const languages = [
  { code: 'it', name: 'Italiano', flag: './flags/italy.png' },
  { code: 'fr', name: 'Français', flag: './flags/french.png' },
  { code: 'en', name: 'English', flag: './flags/england.png' }
];

const LanguageSelect: React.FC = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState(() => {
      const savedLang = localStorage.getItem('language');
      return savedLang || i18n.language;
    });
    const dropdownRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    const changeLanguage = (code: string) => {
      i18n.changeLanguage(code);
      localStorage.setItem('language', code);
      setSelectedLang(code);
      setIsOpen(false);
    };
  
    return (
      <div className="language-select" ref={dropdownRef}>
        <button className="dropdown-btn" onClick={() => setIsOpen(!isOpen)}>
          <img src={languages.find(lang => lang.code === selectedLang)?.flag} alt={selectedLang} />
          <p>{languages.find(lang => lang.code === selectedLang)?.name}</p>
        </button>
        {isOpen && (
          <div className="dropdown-content">
            {languages.map(lang => (
              <div key={lang.code} className="dropdown-item special" onClick={() => changeLanguage(lang.code)}>
                <img src={lang.flag} alt={lang.name} className="flag" />
                <span className="name">{lang.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  export default LanguageSelect;