import axios from "axios";

export const fetchProjects = async () => {
    try {

        const token = localStorage.getItem("jwtToken");

        if (!token) {
            throw new Error("Token not found");
        }

        const response = await axios.get('https://dmz-server.onrender.com/api/repos',
        {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const projects = response.data;

        return projects.filter((project: { logo: string | null }) => project.logo !== null);
    } catch (error) {
        console.error('Error fetching projects:', error);
        throw new Error('Failed to fetch projects');
    }
};