import { NavLink } from "react-router-dom";
import { useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import LanguageSelect from "./LanguageSelect";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "react-i18next";


const Navbar = () => {
  const {t} = useTranslation()
  const [showMenu, setShowMenu] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (isTabletOrMobile) {
      setShowMenu(false);
    }
  };

  const links = [
    { name: t('page:home'), link: "/" },
    { name: t('page:skills'), link: "/skills" },
    { name: t('page:projects'), link: "/projects" },
    { name: t('page:contact'), link: "/contact" },
  ];

  return (
    <header className="header">
      <nav className="nav container glass">
        <NavLink to="/" className="nav__logo">
          Dima<span>.</span>
        </NavLink>

        <div className={`nav__menu ${showMenu ? "show-menu" : ""}`} id="nav-menu">
          <ul className="nav__list">
            {links.map((link, index) => (
              <li key={index} className="nav__item">
                <NavLink to={link.link} className="nav__link" onClick={closeMenuOnMobile}>
                  {link.name}
                </NavLink>
              </li>
            ))}
            {isTabletOrMobile && (
              <li className="nav__item">
                <LanguageSelect />
              </li>
            )}
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        {!isTabletOrMobile && (
          <div className="nav__dropdown">
            <LanguageSelect />
          </div>
        )}

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
